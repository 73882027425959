

function App() {
  return (
    <a className='my-app' href={'https://www.amazon.com/Sony-WH-1000XM5-Canceling-Headphones-Hands-Free/dp/B0B2FCT81R/ref=sr_1_1?crid=1RB08DV29701A&keywords=sony+headphones+wh1000xm5&qid=1696800083&sprefix=sony+headphone%252Caps%252C291&sr=8-1&_encoding=UTF8&tag=candi807-20&linkCode=ur2&linkId=12a77faf92d4f025448df1c4cb6a10fa&camp=1789&creative=9325'}>
      
    </a>
  );
}

export default App;
